
import Vue from 'vue';

import { ContractServiceContract, LoggerContract } from '@/injectables';
import { Services } from '@/injectables/tokens';

export default Vue.extend({
  name: 'GetContract',

  useInjectable: [Services.Contract],

  data(): {
    error: string | undefined;
    tokenLoading: boolean;
  } {
    return {
      error: '',
      tokenLoading: false,
    };
  },
  // TODO this functionality doens't work at all
  mounted() {
    if (this.$route.params?.token) {
      this.handleToken(this.$route.params.token as string);
    }
  },

  methods: {
    async handleToken(token: string): Promise<void> {
      const logger: LoggerContract = this.$container.get('logger');

      this.tokenLoading = true;
      const {
        isErr: isErrToken,
        unwrap: unwrapToken,
        unwrapErr: unwrapErrToken,
      } = await (this.contractService as ContractServiceContract).tokenGetContract(token);

      this.tokenLoading = false;

      if (isErrToken()) {
        const { message = 'Sorry, the download failed' } = unwrapErrToken();
        this.error = message;
        logger.print('error', 'tokenGetContract error', message);
        return;
      }

      const contractData = unwrapToken();

      const { isErr, unwrap, unwrapErr } = await (
        this.contractService as ContractServiceContract
      ).fetchContractAnonymous(token);

      if (isErr()) {
        const { message } = unwrapErr();
        logger.print('error', 'proposal/fetchContract', message);
        this.error = message;
        return;
      }

      const data = unwrap();

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${contractData.fileName}.${contractData.fileType}`);
      document.body.appendChild(link);
      link.click();
    },
  },
});
